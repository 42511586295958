<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-input-group class="mb-2">
          <b-input-group-prepend>
            <b-button variant="primary" @click="list()" :disabled="busy.list">
              <i class="fa fa-search"></i> 검색
              <b-spinner class="ml-1" small v-if="busy.list"></b-spinner>
            </b-button>
          </b-input-group-prepend>
          <b-form-input type="text" placeholder="검색어를 입력해주세요" v-model="form.list.search" @keypress.enter.prevent.stop="list()" v-focus></b-form-input>
        </b-input-group>
      </b-col>
      <b-col cols="6">
        <shop-preset class="mb-2" v-model="form.list.shop"></shop-preset>
      </b-col>
      <b-col cols="6">
        <category-preset class="mb-2" v-model="form.list.category"></category-preset>
      </b-col>
      <b-col cols="12">
        <small>대분류</small><br/>
        <b-form inline>
          <b-button class="mr-2" size="sm" variant="primary" @click="toggleMainCategory">전체 선택</b-button>
          <b-form-checkbox-group v-model="form.list.mainCategory">
            <b-form-checkbox class="col-form-label" v-for="e in options.mainCategory" :key="e.value" :value="e.value">{{e.text}}</b-form-checkbox>
          </b-form-checkbox-group>
        </b-form>
      </b-col>
    </b-row>
    <b-button @click="list()" class="mt-3 mb-2 mr-1" variant="primary">검색</b-button>

    <hr/>

    <div class="mb-2">
      <b-button @click="showDownModal()" class="mr-1" variant="success">매핑 Xlsx Down</b-button>
      <b-button @click="() => {$refs.xlsx.value = null;$refs.xlsx.click()}" class="mr-1" variant="outline-success">매핑 Xlsx Upload</b-button>
      <b-button @click="removeSelected()" class="mr-1" variant="danger" :disabled="busy.remove">선택삭제<b-spinner class="ml-1" small v-if="busy.remove"></b-spinner></b-button>
      <input type="file" ref="xlsx" style="display: none" @change="handleXlsx">
    </div>

    <c-table :table-data="items.list" :fields="fields.list" :perPage.sync="perPage"
             :isBusy="busy.list" :getMoreBusy="busy.listmore" :hasMore="hasMore.list" @btn-clicked="btnAction"
             :caption="items.list.length + ' 개 매핑데이터'" @get-more="list(true)">
    </c-table>

    <b-modal title="카테고리 매핑 다운로드" size="xl" v-model="modal.down">
      <div class="label-sm">Shop 선택(필수)</div>
      <shop-preset v-model="form.down.shop"></shop-preset>
      <b-button @click="downXlsx()" class="mt-1 mr-1" variant="success">Down Xlsx</b-button>

      <template v-slot:modal-footer="{ ok, cancel }">
        <b-button variant="primary" @click="cancel()">
          닫기
        </b-button>
      </template>
    </b-modal>

    <iframe ref="file_frame" name="file_frame" style="width:1px;height:1px;visibility:hidden"></iframe>
    <form :action="$api.getHost() + '/meta/category/mappingXlsx'" ref="file_form" method="POST" target="file_frame" style="width:1px;height:1px;visibility:hidden">
      <input ref="json_data" type="hidden" name="j" />
    </form>
  </div>
</template>
<script>
import {readXlsx} from '@/shared/impexp'

export default {
  name: 'CategoryMapping',
  data() {
    return {
      defaultForm: {
        list: {
          search: '',
          shop: [],
          category: [],
          mainCategory: []
        }
      },
      form: {
        list: {
          search: '',
          shop: [],
          category: [],
          mainCategory: []
        },
        down: {
          shop: [],
        }
      },
      lastBody: {list: {}},
      item: {},
      items: {list: []},
      busy: {list: false, listmore: false, remove: false},
      hasMore: {list: false},
      ac: {list: null}, // abortController
      modal: {down: false},
      perPage: 20,

      fields: {
        list: [
          {key: 'selected', class: 'w-65px'},
          {key: 'key', label: 'Shop ID', class: 'text-center'},
          {key: 'mainCategory', label: '대분류', class: 'text-center'},
          {key: 'cate', label: '수집카테고리'},
          {key: 'matched_category', label: 'BALAAN 카테고리'},
          {key: 'goods_nm', label: 'BALAAN 상품명', class: 'text-center'},
          // {
          //   key: '_actions',
          //   label: '기능',
          //   class: 'text-center',
          //   buttons: [
          //     {label: '삭제', variant: 'danger', event: 'remove'}
          //   ]
          // },
        ]
      },
      options: {
        mainCategory: [
          {text: '남자(M)', value: 'M'},
          {text: '여자(W)', value: 'W'},
          {text: '남녀공용(U)', value: 'U'},
          {text: '골프(G)', value: 'G'},
          {text: '뷰티(B)', value: 'B'},
          {text: '키즈(K)', value: 'K'},
          {text: '홉/리빙(L)', value: 'L'},
          {text: '테크/디지털(D)', value: 'D'},
          {text: '스포츠/웰니스(S)', value: 'S'},
          {text: '반려동물(P)', value: 'P'},
          {text: '아트(A)', value: 'A'},
          // {text: '뷰티(B)', value: 'B'},
        ]
      }
    };
  },
  async created() {
    this.$utils.getStatus(this.$options.name, this, 'perPage');
    this.defaultForm.list.mainCategory = this.options.mainCategory.map(e => e.value);
    this.form.list.mainCategory = this.options.mainCategory.map(e => e.value);
    this.list();
  },
  methods: {
    async list(more) {
      const form = this.form.list;
      const shop = form.shop.map(e => e.shop_id);
      const category = form.category.map(e => e.value);
      await this.$api.postTable(this, '/meta/category/mapping', {...form, shop, category}, {more, fnAssign: this.assignTableData});
    },
    assignTableData(e) {
      e.mainCategory = e.category[0].toUpperCase();
      e.cate = e.key === 'default' ? e.category : e.category.slice(2);
      return e;
    },
    toggleMainCategory() {
      this.form.list.mainCategory = this.form.list.mainCategory.length === this.defaultForm.list.mainCategory.length ? [] : this.options.mainCategory.map(e => e.value);
    },

    btnAction (row, event) {
      if (event === 'remove') {
        this.remove(row);
      }
    },
    showDownModal() {
      this.form.down.shop.splice(0, this.form.down.shop.length);
      this.modal.down = true;
    },
    async downXlsx() {
      if (this.form.down.shop.length === 0) return alert('SHOP 을 선택해주세요');
      this.$refs.json_data.value = JSON.stringify({shop_ids: this.form.down.shop.map(e => e.shop_id)});
      this.$refs.file_form.submit();
    },
    async removeSelected() {
      const selected = this.items.list.filter(e => e.selected);
      if (selected.length === 0) return alert('삭제할 카테고리 매핑을 선택해 주시기 바랍니다.');
      if (!confirm(`${selected.length} 개의 카테고리 매핑을 정말로 삭제하시겠습니까?`)) return;
      this.busy.remove = true;
      const j = await this.$api.postJson('/meta/category/removeMappingMany', {category: selected.map(e => ({key: e.key, category: e.category}))});
      this.busy.remove = false;
      if (j) {
        this.list();
      }
    },
    async remove(row) {
      if (row.item.key === 'default') return alert('default 매핑은 삭제하실 수 없습니다.');
      if (!confirm(`SHOP ${row.item.key} 의 ${row.item.cate} 카테고리 매핑을 삭제하시겠습니까?`)) return;

      this.busy.remove = true;
      let j = await this.$api.postJson('/meta/category/removeMapping', {key: row.item.key, category: row.item.category});
      this.busy.remove = false;
      if (j) {
        this.list();
      }
    },
    async handleXlsx(event) {
      const file = (event.dataTransfer || event.target).files[0];
      if (!file || !file.name.endsWith('xlsx') && !file.name.endsWith('xls')) return this.$utils.alert('xlsx 파일을 업로드해주세요');
      const {sheets} = await readXlsx(file, {multi: true});
      const j = await this.$api.postJson('/meta/category/updateMapping', {sheets});
      if (j) {
        this.$alertTop(`업로드 되었습니다. ${j.cnt} 개 데이터가 수정되었습니다.`);
        this.list();
      }
    },
  },
}
</script>
